import React from 'react'
import { getViewport } from "../../../vicoustic/utility/get-viewport"
import parseMarkdown from "../utils/markdown"
import { Stack } from "./stack"
import styled from 'styled-components'
import { colors } from './colors'
import ImageLoaderInViewWrapper from './image-loader'

const TextStyles = styled.div`
  blockquote, h1, h2, h3 {
    line-height: 110%;
  }
  p, blockquote { font-size: 18px; }
  h1 { font-size:42px; }
  h2 { font-size: 30px; }
  h3 {
    font-size: 24px;
    font-weight: 700;
  }
  p { line-height: 130%; }
  a {
    text-decoration: underline;
    text-decoration-color: ${colors.accent};
    text-decoration-thickness: 0.05em;
    text-underline-offset: 0.1em;
    text-decoration-skip-ink: auto;
  }
  @media screen and (max-width: 768px) {
    p, blockquote { font-size: 16px; }
    h1 { font-size:30px; }
    h2 { font-size: 24px; }
    h3 { font-size: 18px; }
  }
`

export const SectionRenderer = (props) => {
  const { bodyType, ...section } = props
  switch (bodyType) {
    case "text": {
      return <TextStyles
        dangerouslySetInnerHTML={{ __html: parseMarkdown(section.text) }}
      />
    }
    case "image": {
      return <Stack
        wrap="wrap" 
        direction="row"
        justifyContent="center"
        style={{ margin: 0 }}
      >
        {section.images.map(image => (
          <ImageLoaderInViewWrapper
            imgProps={{
              style: section.images.length > 1 ? {
                flex: '0 0 50%',
                maxWidth: 'calc(50% - 32px)',
              } : {
                style: {
                  flexGrow: '1 !important'
                }
              },
            }}
            source={getViewport(image.src, 'm')}
            alt={image.title}
          />
        ))}
      </Stack>
    }
    case "iframe": {
      return (
        <div style={{ position: 'relative', maxWidth: '100%', width: '100%', paddingTop: '56.25%'}}>
          <iframe
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', margin: "auto", display: "block" }}
            width="100%"
            height="100%"
            src={`https://www.youtube-nocookie.com/embed/${section.url}?rel=0`}
            frameborder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      )
    }
  }
}